<template>
    <KCourse loader-class="MBcont" course-id="4" title="Съемный костюм XIX в." :items="items">
        <div class="MBtextcont">
            <p>Сегодня нас ждет раскрой ткани. Работы будет много… Если вы не смогли найти нужный цвет ткани, лент, то смотрите видео по покраске ))</p>
            <h3>4.1 Подбираю ткань, кружева</h3>
        </div>

        <VideoView video-id="c84833dc77334a16abc479965e079a69"/>

        <div class="MBtextcont">
            <h3>4.2 Покраска ткани, лент</h3>
        </div>

        <VideoView video-id="3b92f5450a55448ca12956185509491f"/>

        <div class="MBtextcont">
            <h3>4.3 Раскрой ткани</h3>
        </div>

        <VideoView video-id="773cac55a36b4812ad75d74ebc9db9ca"/>

    </KCourse>
</template>

<script>
    import VideoView from "../../Widgets/VideoView";
    import KCourse from "../../Widgets/KCourse";

    export default {
        name: "MBCourseHead",
        components: {
            VideoView,
            KCourse,
        },
        data() {
        return {
            items: [
                { title: "Подготовка", url:"/mycourses/costume/1"},
                { title: "Снимаем выкройку", url:"/mycourses/costume/2"},
                { title: "Моделируем", url:"/mycourses/costume/3"},
                { title: "Ткань. Раскрой", url:"/mycourses/costume/4"},
                { title: "Нижнее белье", url:"/mycourses/costume/5"},
                { title: "Шьем полочку", url:"/mycourses/costume/6"},
                { title: "Дошиваем платье", url:"/mycourses/costume/7"},
                { title: "Красное платье", url:"/mycourses/costume/8"},
                { title: "Делаем обувь", url:"/mycourses/costume/9"},
                { title: "Ручные швы", url:"/mycourses/costume/10"},
                { title: "Машинные швы", url:"/mycourses/costume/11"},
                { title: "Утюжка", url:"/mycourses/costume/12"},
            ],
        }
        }
    }
</script>